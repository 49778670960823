<template>
    <div>
        <div class="news-head-line">
        </div>
        <div class="container-1" style="margin-bottom:10rem">
            <img class="news-img" src="../../assets/image/news/05-1中图.png"/>
            <div class="news-box">
                <div class="title">第三个3年，比孚再次通过ISO9001认证</div>
                <div class="date">2022/11/04</div>
                <div class="line"></div>
                <div class="content">2022年11月3日，上海比孚信息科技有限公司再一次顺利通过了ISO9001的认证。<br/><br/>

                    ISO9001证书有效期为3年，从2014年开始，这已经是我们第三个3年了。<br/><br/>

                    在此认证审核的过程中，我们自上而下，从公司，部门到项目，展示了各层级的整体目标及相应的质量标准，实施方案和成果。
                    根据公司惯例，根据每年质量目标达成状况，在管理评审会议上审查评估绩效，并制定下一年度的质量目标，使产品质量和服务
                    质量得到持续改善而满足客户需求。专家组对公司管理体系运行情况给予充分肯定，一致同意本次审核顺利通过。<br/><br/>

                    ISO9001是ISO组织颁布的影响面最广的一个管理体系标准。其质量体系文件是指导全司实施质量管理的纲领性文件，是开展各
                    项管理活动的依据和行为准则。经过严格审核的国际标准化的质量管理体系认证，能够加强客户对比孚公司的信任，提高企业的
                    经济效益和社会效益。<br/><br/>

                    本次认证工作的顺利完成，对于公司完善管理体系，进一步提高管理水平具有重要的意义。未来，比孚将不忘初心，继续努力，
                    再上征程！<br/><br/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import store from '@/plugins/store';
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
        };
    },

    mounted() {
        setTimeout(()=>{
            this.refreshIndexInfo({data:'5' , news:true});
        },500)
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
    },
};
</script>

<style lang="less" scoped>
.news-head-line{
    width: 190rem;
    border: 1px solid #F3FAF6;
    margin-top: 11rem;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2);
}
.news-img{
    width: 100rem;
    height: 30rem;
    margin-top: 4rem;
}
.news-box{
    width: 100rem;
    margin: auto;
    margin-top: 6rem; 
    .title{
        height: 2.6rem;
        font-size: 2.6rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #333333;
        line-height: 3.9rem;
        text-align: justify;
    }
    .date{
        height: 1.9rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #999999;
        line-height: 2.4rem;
        text-align: justify;
        margin-top: 2rem;
    }
    .line{
        width: 100rem;
        height: 1px;
        border: 1px solid #EDEDED;
        margin-top: 1rem;
    }
    .content{
        width: 100.1rem;
        height: 46.7rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #4F4F4F;
        line-height: 3rem;
        margin-top: 2.4rem;
        text-align: justify;
    }
}
</style>